//
// variables.scss
//

/* noto-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-sans-v30-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* noto-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/noto-sans-v30-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* noto-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/noto-sans-v30-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(''),
       url('../fonts/lato-v23-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/lato-v23-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}


//color
$primary:   #2c4459;
$success:   #06ba63;
$info:      #64b9dc;
$warning:   #f4b942;
$danger:    #F3672B;
$white:     #ffffff;
$dark:      #152c5c;
$light:     #f8f9fa;
$muted:     #777c7c;
$black:     #252d31;
$purple:    #8850f8;
$cyan:      #00e6e6;
$blue:      #3083DC;
$pink:      #f13e74;

// stylelint-disable
$gray-100:  #f8f9fa;
$gray-200:  #e9ecef;
$gray-300:  #dee2e6;
$gray-400:  #ced4da;
$gray-500:  #adb5bd;
$gray-600:  #6c757d;
$gray-700:  #495057;
$gray-800:  #343a40;
$gray-900:  #212529;

// stylelint-disable
$gray-dark-100:  #323945;
$gray-dark-200:  #343a40;
$gray-dark-300:  #495057;
$gray-dark-400:  #6c757d;
$gray-dark-500:  #adb5bd;
$gray-dark-600:  #ced4da;
$gray-dark-700:  #dee2e6;
$gray-dark-800:  #e9ecef;
$gray-dark-900:  #f8f9fa;

// social networks
$facebook: #4267B2;
$twitter: #14171A;
$whatsapp: #25D366;
$linkedin: #0077b5;
$pinterest: #E60023;

$colors: (
  "primary":  $primary,
  "success":  $success,
  "info":     $info,
  "warning":  $warning,
  "danger":   $danger,
  "dark":     $dark,
  "cyan":     $cyan,
  "blue":     $blue,
  "muted":    $muted,
  "purple":   $purple,
  "pink":     $pink,
  "light":    $light,
  "white":    $white,
);

$theme-colors: (
  "primary":  $primary,
  "success":  $success,
  "info":     $info,
  "warning":  $warning,
  "danger":   $danger,
  "light":    $light,
  "dark":     $dark,
  "muted":    $muted,
  "white":    $white,
);

//box-shadow
$box-shadow:              rgba($black, 0.05) 0px 8px 24px;

// font weight
$font-weight-medium: 500;
$font-weight-semibold: 600;

// font-family
$font-family-base: 'Lato', sans-serif;

