.ul-checklist {
  list-style: none;
  padding: 0;
  li:before {
    content: '✅ ';
  }
  li {
    margin-bottom: 0.3em;
  }
}

.case-element {
  .case-title {
    z-index: 100;
    width: 100%;
    bottom: 0;
    padding: 0.3em;
    background-color: hsla(0, 0%, 0%, 0.5);
  }
}

#aside-sitemap {
  font-size: 0.8em;
}

#table-of-contents {
  a {
    color: #000;
    line-height: 2em;
    text-decoration: underline;
  }
}

mark {
  line-height: 1.5em;
  background-color: hsla(0, 0%, 0%, 0.700);
  color: $white;
}

.bg-dark60 {
  background-color: hsla(0, 0%, 0%, 0.600);
}

.super-center {
  display: grid;
  place-items: center;
}

.w-80 {
  min-width: 80%;
}

.bg-facebook {
  background-color: $facebook;
}

.bg-twitter {
  background-color: $twitter;
}

.bg-whatsapp {
  background-color: $whatsapp;
}

.bg-linkedin {
  background-color: $linkedin;
}

.bg-pinterest {
  background-color: $pinterest;
}

.strokeme {
	font-size: 13rem;
	color: #fff;
	background-color: rgba(0,0,0,0);
	text-shadow: -1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000;
}