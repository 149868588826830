// ### Bootstrap
@import "bootstrap/functions";
@import "bootstrap/mixins/banner";
// @include bsBanner("");

// scss-docs-start import-stack
// Configuration
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/variables-dark";
@import "bootstrap/maps";
@import "bootstrap/mixins";
@import "bootstrap/utilities";

// Layout & components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/accordion";
// @import "bootstrap/breadcrumb";
// @import "bootstrap/pagination";
@import "bootstrap/badge";
// @import "bootstrap/alert";
// @import "bootstrap/progress";
@import "bootstrap/list-group";
@import "bootstrap/close";
// @import "bootstrap/toasts";
@import "bootstrap/modal";
// @import "bootstrap/tooltip";
// @import "bootstrap/popover";
@import "bootstrap/carousel";
// @import "bootstrap/spinners";
// @import "bootstrap/offcanvas";
// @import "bootstrap/placeholders";

// Helpers
@import "bootstrap/helpers";

// Utilities
@import "bootstrap/utilities/api";
// scss-docs-end import-stack



// ### style ###
@import "style/variables";
@import "style/general";
@import "style/helper";
@import "style/components";
@import "style/avatar";
@import "style/menu";
@import "style/home";
@import "style/cta";
@import "style/client-logo";
@import "style/services";
@import "style/features";
@import "style/pricing";
@import "style/testimonials";
@import "style/about";
@import "style/switcher";
@import "style/team";
@import "style/contact";
@import "style/authentication";
@import "style/footer";
@import "style/blog";
@import "style/modal";
@import "custom";

// ### animation ###
@import "aos";
// ### icons ###
@import "bootstrap-icons";

// glider
@import "glider/glider";